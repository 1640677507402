import { Component, For, JSX, createSignal, Show } from 'solid-js';
import { useStyling } from '~/global-contexts/CompanyStyling';

interface TableOfContentsItem {
  id: string;
  title: string;
}

interface Section {
  id: string;
  title: string;
  content: string | string[] | JSX.Element | JSX.Element[];
  subsections?: {
    title: string;
    content: string | string[] | JSX.Element | JSX.Element[];
  }[];
}

const EmailLink: Component<{ email: string }> = (props) => (
  <a href={`mailto:${props.email}`} class="text-primary hover:text-primary/80 underline">
    {props.email}
  </a>
);

export default function PrivacyPolicy() {
  const { companyStyling } = useStyling();

  const [activeSection, setActiveSection] = createSignal<string>('');

  const tableOfContents: TableOfContentsItem[] = [
    { id: 'personal-data', title: 'Personal Data We Collect' },
    { id: 'data-usage', title: 'How We Use Your Personal Data' },
    { id: 'data-sharing', title: 'How We Share Your Personal Data' },
    { id: 'cookies', title: 'Cookies and Other Tracking Technologies' },
    { id: 'dnt', title: '"Do Not Track" Signals' },
    { id: 'security', title: 'Security' },
    { id: 'third-party', title: 'Third Party Links' },
    { id: 'children', title: "Children's Privacy" },
    { id: 'california', title: 'Notice to California Residents' },
    { id: 'contact', title: 'How to Contact Us' },
  ];

  const scrollToSection = (id: string) => {
    setActiveSection(id);
    const element = document.getElementById(id);
    if (element) {
      const offset = 100;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  const externalLink = (href: string, text: string) => (
    <a href={href} target="_blank" rel="noopener noreferrer" class="text-primary hover:text-primary/80 underline">
      {text}
    </a>
  );

  const introduction: Section = {
    id: 'introduction',
    title: 'Introduction',
    content: [
      `${
        companyStyling?.()?.name
      } ("we", "our", or "us") respects the privacy of your information. This Privacy Policy is designed to assist you in understanding how we collect, use, share, and safeguard your information. This Privacy Policy applies to individuals who access this website and our mobile applications (collectively, "Site") and any of our online services (collectively, "Services").`,
      'From time to time, we may change this Privacy Policy. If we do, we will post an amended version on this webpage. Please review this Privacy Policy periodically.',
    ],
  };

  const sections: Section[] = [
    {
      id: 'personal-data',
      title: '1. Personal Data We Collect',
      content:
        'We collect personal data from you through your use of the Site and Services. Personal data is information that is linked or reasonably linkable to an identified or identifiable individual. We collect the following types of personal data:',
      subsections: [
        {
          title: 'Personal Data You Provide',
          content: [
            'Depending on how you interact with our Site and Services, we will collect the following personal data that you voluntarily provide to us for the following purposes:',
            <ul class="list-disc space-y-2 pl-6 text-gray-600">
              <li class="pl-2">
                <i>Create an Account.</i> If you create an account, you will provide us with your name, email address, phone number, and a
                password for future login purposes. Property managers and owners may also need to provide title, company name and address,
                employee identification number or Social Security number, and financial information (including bank statements).
              </li>
              <li class="pl-2">
                <i>Sign In.</i> When you sign into your account, you will provide us with your login credentials.
              </li>
              <li class="pl-2">
                <i>Submit a Rental Application.</i> If you apply to rent a property, you will provide us with your name, email address,
                phone number, current and previous physical addresses, employer name, employment history, financial information, income
                verification, and Social Security number. We use TransUnion to process rental applications. TransUnion may collect, record,
                and store the information you provide in your application. Please review TransUnion's privacy policy{' '}
                {externalLink('https://www.transunion.com/privacy/transunion?atvy=%7B%22254739%22%3A%22Experience+B%22%7D', 'here')}.
              </li>
              <li class="pl-2">
                <i>Make a Maintenance Request.</i> If you submit a maintenance request as a tenant of a property, you will provide your
                name, physical address, and any details you choose to provide regarding your request.
              </li>
              <li class="pl-2">
                <i>Pay Your Rent.</i> When you pay your rent, you will provide us with your login credentials, name, physical address, and
                financial information. We may use a third-party provider to process your payment, such as{' '}
                {externalLink('https://paymentcloudinc.com/privacy/', 'PaymentCloud')} or{' '}
                {externalLink('https://stripe.com/privacy', 'Stripe')}.
              </li>
              <li class="pl-2">
                <i>Contact Us/Book a Demo.</i> If you contact us or book a demo, you will provide us with your name, email address, and
                phone number.
              </li>
            </ul>,
          ],
        },
        {
          title: 'Personal Data as You Navigate Our Site',
          content: [
            'We automatically collect certain personal data through your use of our Site and our use of cookies and other tracking technologies, such as the following:',
            <ul class="list-disc space-y-2 pb-2 pl-6 text-gray-600">
              <li class="pl-2">
                <i>Usage Information.</i> For example, the pages on the Site you access, the frequency of access, and what you click on
                while on the Site.
              </li>
              <li class="pl-2">
                <i>Device Information.</i> For example, hardware model, operating system, application version number, and browser.
              </li>
              <li class="pl-2">
                <i>Mobile Device Information.</i> Aggregated information about whether the Site is accessed via a mobile device or tablet,
                the device type, and the carrier.
              </li>
              <li class="pl-2">
                <i>Location Information.</i> Location information from Site visitors on a city-regional basis.
              </li>
            </ul>,
            <p class="mb-4 leading-relaxed text-gray-600">
              For more information on our cookie usage see our{' '}
              <span onClick={() => scrollToSection('cookies')} class="text-primary hover:text-primary/80 cursor-pointer underline">
                "Cookies and Other Tracking Technologies"
              </span>{' '}
              section below.
            </p>,
          ],
        },
        {
          title: 'Personal Data We Collect About You from Other Sources',
          content:
            'In some cases, we may receive personal data about you from other sources. This includes consumer reporting agencies, property owners and managers, advertising networks, operating systems and platforms, mailing list providers, and advertising and marketing partners.',
        },
      ],
    },
    {
      id: 'data-usage',
      title: '2. How We Use Your Personal Data',
      content: [
        'In addition to the purposes stated above, we may use all the personal data we collect in accordance with applicable law such as to:',
        <ul class="list-disc space-y-2 pb-2 pl-6 text-gray-600">
          <li class="pl-2">Maintain and improve our Site and Services;</li>
          <li class="pl-2">Protect the security and integrity of our Site and Services;</li>
          <li class="pl-2">
            Investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to
            the physical safety of any person, or violations of any of our terms of use policies, and to otherwise fulfill our legal
            obligations;
          </li>
          <li class="pl-2">Advertise products and services to you;</li>
          <li class="pl-2">Monitor compliance with and enforce this Privacy Policy and any applicable agreements and policies;</li>
          <li class="pl-2">Defend our legal rights and the rights of others;</li>
          <li class="pl-2">Fulfill any other purposes for which you provide it;</li>
          <li class="pl-2">
            For any purpose that is reasonably necessary to or compatible with the original purpose for which we collected the personal data
            as disclosed to you; and
          </li>
          <li class="pl-2">Comply with applicable law.</li>
        </ul>,
      ],
    },
    {
      id: 'data-sharing',
      title: '3. How We Share Your Personal Data',
      content: [
        'We may share the personal data that we collect about you in the following ways:',
        <ul class="list-disc space-y-2 pb-2 pl-6 text-gray-600">
          <li class="pl-2">
            With vendors who perform data or Site-related services on our behalf (e.g., email, hosting, maintenance, backup, analysis,
            etc.);
          </li>
          <li class="pl-2">To the extent that we are required to do so by law;</li>
          <li class="pl-2">With vendors to prepare, deploy and analyze advertising content;</li>
          <li class="pl-2">In connection with any legal proceedings or prospective legal proceedings;</li>
          <li class="pl-2">
            To establish, exercise, or defend our or a third party's legal rights, including providing information to others for the
            purposes of fraud prevention;
          </li>
          <li class="pl-2">
            With any person who we reasonably believe may apply to a court or other competent authority for disclosure of that personal data
            where, in our reasonable opinion, such court or authority would be reasonably likely to order disclosure of that personal data;
          </li>
          <li class="pl-2">
            With any other person or entity as part of any business or asset sale, equity transaction, merger, acquisition, bankruptcy,
            liquidation, or similar proceeding, or in preparation for any of these events;
          </li>
          <li class="pl-2">With any other person or entity where you consent to the disclosure; and</li>
          <li class="pl-2">
            For any other purpose disclosed by us when you provide the personal data or for any other purpose we deem necessary, including
            to protect the health or safety of others.
          </li>
        </ul>,
      ],
    },
    {
      id: 'cookies',
      title: '4. Cookies and Other Tracking Technologies',
      content: [
        'Like many other companies, we use cookies and other tracking technologies (collectively, "Cookies"). Cookies are small files of information that are stored by your web browser software on your computer hard drive, mobile or other devices (e.g., smartphones or tablets).',
        'We use Cookies to:',
        <ul class="list-disc space-y-2 pb-2 pl-6 text-gray-600">
          <li class="pl-2">Estimate audience size and usage patterns;</li>
          <li class="pl-2">
            Understand and save your preferences for future visits, allowing us to customize the Site and Services to your individual needs;
          </li>
          <li class="pl-2">Advertise new content and services that relate to your interests;</li>
          <li class="pl-2">Keep track of advertisements and search engine results;</li>
          <li class="pl-2">
            Compile aggregate data about site traffic and site interactions to resolve issues and offer better site experiences and tools in
            the future; and
          </li>
          <li class="pl-2">Recognize when you return to the Site.</li>
        </ul>,
        'We set some Cookies ourselves, while separate entities set other Cookies. We use Cookies other entities set to provide us with useful information, to help us improve our Site and Services, to conduct advertising, and to analyze the effectiveness of advertising.',
      ],
      subsections: [
        {
          title: 'How You Can Opt Out of Cookies',
          content: [
            'You can block Cookies by changing your Internet browser settings to refuse all or some Cookies. If you choose to block all Cookies (including essential Cookies) you may not be able to access all or parts of the Site.',
            <>
              You can find out more about Cookies and how to manage them by visiting{' '}
              {externalLink('http://www.aboutcookies.org', 'AboutCookies.org')} or{' '}
              {externalLink('http://www.allaboutcookies.org', 'allaboutcookies.org')}.
            </>,
            <i>Advertising Industry Resources</i>,
            <>
              You can understand which entities have currently enabled Cookies for your browser or mobile device and how to opt out of some
              of those Cookies by accessing the{' '}
              {externalLink('http://optout.networkadvertising.org/#!/', "Network Advertising Initiative's website")} or the{' '}
              {externalLink('http://optout.aboutads.info/#!/', "Digital Advertising Alliance's website")}. For more information on mobile
              specific opt-out choices, visit the{' '}
              {externalLink('http://www.networkadvertising.org/mobile-choices', "Network Advertising Initiative's Mobile Choices website")}.
            </>,
            'Please note these opt-out mechanisms are specific to the device or browser on which they are exercised. Therefore, you will need to opt out on every browser and device that you use.',
          ],
        },
      ],
    },
    {
      id: 'dnt',
      title: '5. "Do Not Track" Signals',
      content:
        'Some internet browsers incorporate a "Do Not Track" feature that signals to websites you visit that you do not want to have your online activity tracked. Given that there is not a uniform way that browsers communicate the "Do Not Track" signal, the Site does not currently interpret, respond to or alter their/its practices when they receive/it receives "Do Not Track" signals.',
    },
    {
      id: 'security',
      title: '6. Security',
      content:
        'We maintain commercially reasonable security measures to protect the personal data we collect and store from loss, misuse, destruction, or unauthorized access. However, no security measure or modality of data transmission over the Internet is 100% secure. Although we strive to use commercially acceptable means to protect your personal data, we cannot guarantee absolute security.',
    },
    {
      id: 'third-party',
      title: '7. Third Party Links',
      content:
        'The Site may contain links that will let you leave the Site and access another website. Linked websites are not under our control. We accept no responsibility or liability for these other websites.',
    },
    {
      id: 'children',
      title: "8. Children's Privacy",
      content:
        'The Site and Services are not intended for children under 13 years of age. We do not knowingly collect, use, or disclose personal data from children under 13.',
    },
    {
      id: 'california',
      title: '9. Notice to California Residents',
      content: (
        <p>
          If you are a California resident and have an established business relationship with us, you may request that we do not disclose
          your personal information to third parties for the third parties' own direct marketing purposes (as those terms are defined in
          California Civil Code § 1798.83). You may submit such a request by emailing us at{' '}
          <EmailLink email={companyStyling?.()?.email ?? ''} />.
        </p>
      ),
    },
    {
      id: 'contact',
      title: '10. How to Contact Us',
      content: (
        <p>
          To contact us for questions or concerns about our privacy policies or practices, please email us at{' '}
          <EmailLink email={companyStyling?.()?.email ?? ''} />.
        </p>
      ),
    },
    {
      id: '10DLC',
      title: '11. SMS and Text Messaging Terms',
      content: [
        'All the above categories exclude text messaging originator opt-in data and consent; this information will not be shared with any third parties.',
        'We will not share or sell your mobile information with third parties for promotional or marketing purposes. We may share your Personal Data, including your SMS opt-in or consent status, with third parties that help us provide our messaging services, including but not limited to platform providers, phone companies, and any other vendors who assist us in the delivery of text messages.',
      ],
    },
  ];

  const renderContent = (content: string | string[] | JSX.Element | JSX.Element[]) => (
    <Show
      when={typeof content === 'string'}
      fallback={
        <Show when={Array.isArray(content)} fallback={content}>
          <For each={content as string[]}>{(item) => <p class="mb-4 leading-relaxed text-gray-600">{item}</p>}</For>
        </Show>
      }>
      <p class="mb-4 leading-relaxed text-gray-600">{content as string}</p>
    </Show>
  );

  return (
    <div class={`mx-auto w-full max-w-4xl bg-primary-color px-4 py-8`}>
      <div class="rounded-lg bg-white p-6 shadow md:p-8">
        <h1 class="mb-8 text-3xl font-bold text-gray-900">Privacy Policy</h1>
        <div class="mb-8 text-sm text-gray-600">Effective and Last Updated as of November 13, 2024</div>
        <section class="mb-4 scroll-mt-4" id={introduction.id}>
          <h2 class="mb-4 text-xl font-semibold text-gray-900">{introduction.title}</h2>
          <div class="prose prose-gray max-w-none">{renderContent(introduction.content)}</div>
        </section>
        <nav class="mb-4 rounded-lg bg-gray-50 p-4">
          <h2 class="mb-2 ml-3.5 leading-relaxed text-gray-600">This Privacy Policy covers the following topics:</h2>
          <ol class="list-inside list-decimal space-y-2">
            <For each={tableOfContents}>
              {(item) => (
                <li class="ml-4">
                  <button
                    onClick={() => scrollToSection(item.id)}
                    class={`text-primary text-left hover:text-hover-color hover:underline focus:outline-none`}>
                    {item.title}
                  </button>
                </li>
              )}
            </For>
          </ol>
        </nav>
        <For each={sections}>
          {(section) => (
            <section class="mb-12 scroll-mt-20" id={section.id} classList={{ 'bg-primary/10': activeSection() === section.id }}>
              <h2 class="mb-4 text-xl font-semibold text-gray-900">{section.title}</h2>
              <div class="prose prose-gray max-w-none">{renderContent(section.content)}</div>

              <Show when={section.subsections}>
                <For each={section.subsections}>
                  {(subsection) => (
                    <div class="mt-6">
                      <h3 class="mb-3 text-lg font-medium text-gray-900">{subsection.title}</h3>
                      <div class="prose prose-gray max-w-none">{renderContent(subsection.content)}</div>
                    </div>
                  )}
                </For>
              </Show>
            </section>
          )}
        </For>
      </div>
    </div>
  );
}
